import React from 'react'

function ChevronDownIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="#023047" height="24" viewBox="0 0 365.696 365.696" width="24">
        <g>
            <g>
                <polygon points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093   "/>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        </svg>
        )
}

export default ChevronDownIcon
