import React from 'react'
import tw, {styled} from "twin.macro";
import { SectionHeading } from "src/ui-treact/components/misc/Headings.js";

const StyledDiv = styled.div`
width: 100%;
min-width: 320px;
text-align: center;
display: flex;
flex: 1;
padding-bottom:20px;

.border-left {
    border-left: 5px solid #9aca38;
    padding-left:30px;
    padding-top:20px;
    padding-bottom:20px;
    text-align:left;
  }
`
const MainDiv = styled.div`
${tw`text-center mx-4 px-8 py-3 -mt-1 text-gray-800 transition duration-300 cursor-pointer select-none sm:px-10 sm:py-3 hover:text-gray-900 border`}
background-color:#fff;
background-color: ${props=>props.background_color};
border-color: ${props=>props.border_color};
`;

const Heading = tw(SectionHeading)`my-4 font-black text-center text-2xl sm:text-xl lg:text-2xl md:text-left  text-black font-normal font-semibold`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-base leading-relaxed text-black font-normal`;
export default function TextCard(props) {
    return (
        <StyledDiv>
            <MainDiv background_color={props.background_color} border_color={props.border_color}>
                <Heading dangerouslySetInnerHTML={{ __html: props.heading }}></Heading>
                <Description dangerouslySetInnerHTML={{ __html: props.description }}></Description>
                {props.remember_text.length > 0 && 
                <div className="border-left"
                dangerouslySetInnerHTML={{ __html: props.remember_text}}
                >
                </div>}
            </MainDiv>
        </StyledDiv>
    )
}
