import React, { useState } from 'react';
import styled from 'styled-components'
import _ from 'lodash';
import Store from 'src/stores/common-store';
import FlavourAccord from './FlavourAccord';
import TextCardList from './TextCardList';
import ImageOnText from './ImageOnText';

const StyledDiv = styled.div`
max-width: 1200px;

margin: 0 auto;
background: white;
  .select-box {
    border: 1px solid lightgray;
  }
  .btn-selected {
    background-color: #fd6e0d;
    color: #fff;
  }
  .btn-normal {
   background-color: #fff;
   color: #000;
   border: 1px solid #edf2f7;
 }
  .Add-to-cart {
    background-color: #fd6e0d;
  }
  .btn-flav {
    border: 1px solid lightgray;
    background-color: ${props=>props.originalSelected ? '#fff':'#fd6e0d'};
    color: ${props=>props.originalSelected ? '#000':'#fff'};
  }
  .mail-box {
    border-bottom: 2px solid black;
  }
  .mail-box input:focus {
    border: none;
  }
  footer {
    background-color: #fffcfc;
  }
.img-class {
   @media (min-width:767px) {
      max-width: 500px;
   }
}
button:focus {outline:0;}
select:focus {outline:0;}
`


export default function BuyProlon(props) {
let { productVariants } = props;
console.log('productVariants', productVariants)
productVariants = productVariants.map((e) => {
   let { selectedOptions } = e;
   if (selectedOptions && selectedOptions.length > 0) {
     selectedOptions.forEach((option,i) => {
       e[`option${i+1}`] = option.value;
     });
   }
   return e;
 });

const Option1Array=_.uniqBy(productVariants,'option1').map(e=>e.option1);

const [flavour, setflavour] = useState(Option1Array[0]);
const [Option2Array, setOption2Array] = useState(productVariants.filter(e=>e.option1==flavour));
const [plan, setplan] = useState(Option2Array[0].option2);
const [price, setprice] = useState(Option2Array[0].price);
const [compareAtPrice, setcompareAtPrice] = useState(Option2Array[0].compareAtPrice);
const [selectedImage, setselectedImage] = useState(Option2Array[0].image.originalSrc)

const option1 = Option1Array.map((option)=>{
   return (
   <button class={`${flavour == option ? 'btn-selected' : 'btn-normal'}  text-sm px-5 py-2 text-md mx-1`}
   onClick={()=>{
      setflavour(option)
      changeOption2(option)
   }}
   >{option}</button>
   )
}) 

const option2 = Option2Array.map((option,i)=>{
   let addon = null
   if(option.option2.includes("Subscri") || option.option2.includes("Single Box Order")){
      addon = <p class="md:pl-3 text-xs">(1 Prolon + 30 intermittent Fasting Bars)</p>
   }
   let subscription = null
   if (option.option2.includes("Subscri")){
      subscription =
          <div class="select-box px-3 py-1 mt-2 flex md:ml-6 ">
            <select name="" id=""
            onChange={(e)=>{
               let {name, value} = e.target;
               console.log('name', name)
               console.log('value', value)
            }}
            >
               <option>Deliver every month</option>
               <option>Deliver every 3 months</option>
            </select>
         </div> 
   }

   return(
      <div class="mt-5 ml-4 flex flex-1 justify-between items-center w-11/12 ">
               <div class="flex justify-end content-start items-center">
                  <input type="radio" name='radio1' id={'varient' + i}
                  checked={plan == option.option2 ? true : false}
                  onClick={() => {
                     setplan(option.option2);
                     setprice(option.price)
                     setcompareAtPrice(option.compareAtPrice)
                     setselectedImage(option.image.originalSrc)
                  }}
               />
                  <div class="flex flex-col justify-end items-start">
                     <label class="text-base font-bold md:pl-3" for={'varient' + i}>{option.option2}</label>
                     {addon}
                     {subscription}
                  </div>
               </div>

               <div class="text-lg flex flex-1 justify-end">
                  <p class="line-through">{option.compareAtPrice}</p>
                  <p class="text-red-600 pl-2 font-semibold">{option.price}</p>
               </div>
      </div>
   )
})

const changeOption2 = (option) =>{
   let newArray = productVariants.filter(e=>e.option1==option)
   setOption2Array(newArray);

   let optionSelected = _.find(newArray, { 'option2': plan});

   setprice(optionSelected.price)
   setcompareAtPrice(optionSelected.compareAtPrice)
   setselectedImage(optionSelected.image.originalSrc)
}
const [adding, setadding] = useState(false);
let finalData = props.data?.schema_json;

    return (
        <StyledDiv id={finalData?.section_id}>
      <div class="px-1 md:px-16 mt-4 md:mt-8 flex flex-col md:flex-row md:justify-around items-center">
         <div class="flex flex-col md:mb-auto px-0 mb-12  px-1 md:px-12 w-full md:w-1/2">
            <img className="img-class" src={selectedImage} class="img1 w-full" />
            {(plan.includes("Subscri") || plan.includes("Single Box Order")) && <img className="img-class" src="https://cdn.shopify.com/s/files/1/0126/2921/3243/files/25Off_Fresh_Start_540x.png?v=1609967233" class="img2 pt-3 w-full" />}
         </div>
          {/* Content Section  */}
         <div class="flex flex-col items-center md:items-start w-full md:w-1/2  px-1 md:pl-12">
            <h1 class="text-3xl font-bold">ProLon®</h1>
            <div class="flex items-center mt-5">
               <p class="line-through text-lg">${compareAtPrice}</p>
               <p class="text-lg px-2">${price}</p>
               <p class="text-xs pl-2 text-red-600 md:text-lg md:font-semibold">Save ${compareAtPrice-price}</p>
            </div>
            <div class="mt-5">
               {option1}
            </div>
            {
            flavour == Option1Array[0] ? <p class="text-left text-sm md:text-base mt-5"> 
                <i class="font-bold not-italic">New Soup Flavors: </i>
                Butternut Squash, Black Bean, Butternut Squash Quinoa, Spinach & White Bean, Tomato
            </p>
            :
            flavour == Option1Array[1] ? <p class="text-left text-sm md:text-base mt-5"> 
            <i class="font-bold not-italic">Soups: </i>
            Vegetable, Minestrone, Vegetable Quinoa, Mushroom, Tomato
            </p>
            :null
            }
            
            {option2}
            <button class="Add-to-cart px-8 py-3 mb-10 text-white text-sm font-normal mt-10 md:px-8"
            onClick={async ()=>{
               setadding(true);
               let subscription =  plan.includes("Subscri")? true:false
               let data = Store.getDataFromVarient({
                  variants:productVariants,
                  option1:flavour,
                  option2:plan,
                  free_gift:false,
                  quantity:1,
                  subscription:subscription
               })
               await Store.addItem(data);
               Store.showCart()
               setadding(false);

            }}
           >
              {adding ? 'Adding...' : 'ADD TO CART'}
           </button>
         </div>
      </div>
   <FlavourAccord />
   </StyledDiv>
    )
}
