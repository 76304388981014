import React, { useState } from 'react'
import styled from 'styled-components'
import tw from "twin.macro";

const StyledDiv = styled.div`
width: 100%;
margin: 0 auto;
margin-bottom: 20px;

.mainDiv{
    width: 100%;
    margin: 0 auto;
    
    background:transparent;
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
      img {
        max-width: 16%;
        height: auto;
      }
}
`
const Divider = tw.div`py-3 border-b-2 border-gray-100`


export default function FlavourCard(props) {
    let { urls } = props;
    let images =  urls.map((url)=>{
        return (
         <img src={url} />
        )
    })

    return (
        <StyledDiv>
            <div>
            <h1
                class="text-black font-semibold p-2 text-center text-4xl heading-bg text-brand-green">
                {props.heading}
            </h1>
            </div>
            <div className="mainDiv">
            {images}
            </div>
            {props.divider ? <Divider /> : null}
        </StyledDiv>
    )
}
